import { type ReactNode } from 'react'
import * as styles from './style.css'

type Props = {
  children: ReactNode
  className?: string
  size?: 'small' | 'medium' | 'large' | 'full'
}

export const LayoutInner = ({ children, className, size = 'medium' }: Props) => {
  return (
    <div className={[styles.layoutInner, styles[`layoutInner__${size}`], className].filter(Boolean).join(' ')}>
      {children}
    </div>
  )
}
